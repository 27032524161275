function Game() {
  const html = `<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <title>Ploof</title>
  </head>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/pixi.js/5.1.3/pixi.min.js"></script>
  <body>
    <script type="text/javascript">
      let type = "WebGL";
      if (!PIXI.utils.isWebGLSupported()) {
        type = "canvas";
      }

      var dimension = 500,
        colorBook = [
          [0x0d75ff, 0x00d973, 0xff8c00, 0x340059, 0xf5f5b8],
          [0xf5f5b8, 0xf20000, 0x9c52f2, 0x58771e, 0x0d2b52],
          [0xb875eb, 0xc05200, 0x94ff94, 0xed3d66, 0x405416],
          [0xffab00, 0x00cf91, 0xa93400, 0x202d85, 0xffcfc4],
          [0xff5ec4, 0x9161f2, 0x3400a3, 0x0d75ff, 0x9cb29e],
          [0x0d2b52, 0xff5200, 0x4f8fe6, 0xa6d40d, 0xc0b490],
          [0xf20000, 0xbfffe6, 0xffe600, 0x0d75ff, 0x340059],
          [0xa93400, 0x58771e, 0x202d85, 0xff8c00, 0xbfabcc],
        ];

      i = Math.floor(Math.random() * 8);
      j = Math.floor(Math.random() * 7);
      k = Math.floor(Math.random() * 6);

      set1 = colorBook.splice(i, 1);
      set2 = colorBook.splice(j, 1);
      set3 = colorBook.splice(k, 1);

      var colorDictionary = [set1, set2, set3],
        leadArray = [...colorDictionary[0]][0];

      let Application = PIXI.Application,
        loader = PIXI.loader,
        resources = PIXI.loader.resources,
        Graphics = PIXI.Graphics,
        TextureCache = PIXI.utils.TextureCache,
        Sprite = PIXI.Sprite,
        Text = PIXI.Text,
        TextStyle = PIXI.TextStyle,
        Container = PIXI.Container;

      let app = new Application({
        width: dimension,
        height: dimension,
        antialias: true,
        resolution: 1,
        backgroundColor: [...leadArray][4],
      });

      document.body.appendChild(app.view);
      loader.add("").load(setup);

      var segments = [],
        maxSnake,
        maxObjects = 1,
        bit,
        speed,
        objects = [],
        score = 0,
        scores = [],
        borders = [],
        leadColor = [...leadArray][0],
        borderColorArray = [...leadArray].slice(0, 4),
        objectColorArray = [...leadArray].slice(0, 4),
        sentEndPost = false,
        lastBorderColor = {},
        i = 0,
        objectGrid = [],
        level = 1,
        oldLevel = 1,
        restart = false,
        restartLink = "",
        shortenCounter = 0,
        extendSnake = false,
        ƒ = parseInt(
          window.parent.document.getElementById("baseScore").textContent
        ),
        targetScore = parseInt(
          window.parent.document.getElementById("targetScore").textContent
        ),
        baseHit = false,
        targetHit = false;

      function setup() {
        // Establishing game dimensions
        maxSnake = 150;
        bit = maxSnake / 6;

        // Establishing the object grid based on given dimensions
        rowArray = [];
        for (i = 2 * bit; i <= dimension - 2 * bit; i += bit) {
          rowArray.push(i);
        }

        for (i = 0; i < rowArray.length; i++) {
          for (j = 0; j < rowArray.length; j++) {
            objectGrid.push([rowArray[i], rowArray[j]]);
          }
        }

        // Establishing scene and game variables
        gameScene = new Container();
        app.stage.addChild(gameScene);

        gameOverScene = new Container();
        app.stage.addChild(gameOverScene);
        gameOverScene.visible = false;

        speed = 3;
        clientX = null;
        clientY = null;
        oldTime = Date.now();
        startSnake = drawSnake(150, 150, maxSnake, 0, leadColor);
        segments.push(startSnake);
        gameScene.addChild(startSnake);

        setBorders();

        oldSpeedTime = Date.now();
        // KEYBOARD CONTROLS
        let key = keyboard();
        key.press = () => {
          length = segments.length;
          head = segments[length - 1];
          tail = segments[0];

          turnSnake(head);
        };

        //Set the game state
        state = play;

        //Start the game loop
        app.ticker.add((delta) => gameLoop(delta));
      }

      function gameLoop(delta) {
        //Update the current game state:
        state(delta);
      }

      function play(delta) {
        if (totalSnakeLength() < bit) {
          maxSnake = 0;
          state = end;
        }
        speed *= delta;
        length = segments.length;
        head = segments[length - 1];
        tail = segments[0];

        // SNAKE MOVEMENT: CHECK IF THE SNAKE IS MAX LENGTH, THEN TRAVEL IN ONE DIRECTION; OTHERWISE RUN REFRESH HEAD + TAIL
        if (length > 1) {
          refreshHead(head);
          refreshTail(tail);
        } else {
          moveSnake(head);
        }

        // COLLISION DETECTION BETWEEN HEAD AND OBJECTS / BORDERS
        detectObjectCollision(head);
        detectBorderCollision(head);

        // EVERY FIVE SECONDS, CHECK TO SEE IF A BUMPER EXISTS, IF YES, SKIP; OTHERWISE PLACE A BUMPER; CHECK IF MAX OBJECTS ARE PRESENT
        checkObjects();
        setBorders();
        if (shortenCounter > 0) {
          shortenTail();
        }
        resetLevel();
        speed /= delta;
      }

      function end() {
        let style = new TextStyle({
          fontFamily: "Futura",
          fontSize: 38,
          fill: 0xff8c00,
        });
        message = new Text("GAME OVER", style);
        message.x = 138;
        message.y = app.stage.height / 2 - 32;
        gameOverScene.addChild(message);
        // sendPost("end");
        restart = true;
        gameOverScene.visible = true;
        app.ticker.stop();
        PIXI.Ticker.system.stop();
      }

      // HELPER FUNCTIONS
      function resetLevel() {
        // UPDATE LEVEL OR SCORE THRESHOLD FOR LEVEL RESET
        if (
          // (score == baseScore && !baseHit) ||
          score == targetScore &&
          !targetHit
        ) {
          colorDictionary.push(colorDictionary.shift());
          colorSet = [...colorDictionary[0]][0];

          // Update background color
          app.renderer.backgroundColor = colorSet[4];

          // Update border colors
          while (borders.length > 0) {
            border = borders.pop();
            destroyObject(border);
          }

          // Update object colors
          while (objects.length > 0) {
            object = objects.shift();
            objectGrid.push(object.centerPoint);
            destroyObject(object);
          }

          // Update border color array
          for (i = 0; i < borderColorArray.length; i++) {
            borderColorArray[i] = colorSet[i];
          }

          // Update object color array
          for (i = 0; i < objectColorArray.length; i++) {
            objectColorArray[i] = colorSet[i];
          }
          setBorders();
          recolorSnake(objectColorArray[0]);
          oldlevel = level;
          maxSnake = 150;
          maxObjects = Math.floor(maxObjects / 2);
          extendSnake = true;
          // if (score == baseScore) {
          //   baseHit = true;
          //   speed += 0.25;
          // } else
          if (score == targetScore) {
            targetHit = true;
            speed += 0.5;
          }
        }
      }

      function recolorSnake(color) {
        leadColor = color;
        for (i = 0; i < segments.length; i++) {
          segment = segments[i];
          segment.color = color;
          segment.beginFill(color);

          x = segment.x;
          y = segment.y;
          angle = segment.angle;
          length = segment.length;

          recoloredSegment = drawSnake(x, y, length, angle, color);
          segments.splice(i, 1, recoloredSegment);

          gameScene.addChild(recoloredSegment);
          gameScene.removeChild(segment);
          segment.visible = false;
        }
      }

      // COLLISION DETECTION AND REACTIONS
      function detectObjectCollision(head) {
        for (i = 0; i < objects.length; i++) {
          object = objects[i];
          collision = objectCollision(head, object);
          if (collision) {
            objectGrid.push(object.centerPoint);
            objects.splice(i, 1);
            destroyObject(object);
            checkScore(head, object);
          }
        }
      }

      function detectBorderCollision(head) {
        for (i = 0; i < borders.length; i++) {
          border = borders[i];
          collision = objectCollision(head, border);
          if (collision) {
            // ONLY BOUNCE IF COLLISION IS RESULT OF DRIVING INTO THE WALL
            if ((border.angle + 270) % 360 == head.angle) {
              borders.splice(i, 1);
              leadColor = border.color;
              destroyObject(border);
              bounceSnake(head);
              if (score > 7) {
                increaseDifficulty("bounce");
              }
            }
          }
        }
      }

      function totalSnakeLength() {
        totalLength = 0;
        for (i = 0; i < segments.length; i++) {
          if (i == segments.length - 1) {
            totalLength += segments[i].length;
          } else {
            totalLength += segments[i].length - bit;
          }
        }
        return totalLength;
      }

      function checkScore(head, object) {
        tail = segments[0];
        if (head.color == object.color) {
          score += 1;
          increaseDifficulty();
        } else {
          totalLength = totalSnakeLength();
          if (totalLength >= 2 * bit - speed) {
            shortenCounter += 10;
          } else {
            maxSnake = 0;
            state = end;
          }
        }
      }

      function shortenTail() {
        tail = segments[0];
        x = tail.x;
        y = tail.y;
        angle = tail.angle;
        length = tail.length;
        shortenCounter -= 1;

        if (length > bit) {
          deltaTailCollision =
            length - bit / 10 < bit ? length - bit : bit / 10;
          length -= deltaTailCollision;

          x +=
            deltaTailCollision * Math.round(Math.cos(angle * (Math.PI / 180)));
          y +=
            deltaTailCollision * Math.round(Math.sin(angle * (Math.PI / 180)));

          newTail = drawSnake(x, y, length, angle, tail.color);
          segments.splice(0, 1, newTail);

          tailIndex = gameScene.getChildIndex(tail);
          gameScene.addChildAt(newTail, tailIndex);
          gameScene.removeChild(tail);
          tail.visible = false;

          maxSnake -= bit / 10;
        } else {
          if (segments.length > 1) {
            segments.shift();
            gameScene.removeChild(tail);
            tail.visible = false;
            maxSnake -= bit / 10;
          } else {
            maxSnake = 0;
            state = end;
          }
        }
      }

      // SNAKE MOVEMENTS
      function bounceSnake(head) {
        x = head.x;
        y = head.y;
        length = head.length;
        angle = head.angle;

        head = getCoordinates(head);

        x = head.oppositeX;
        y = head.oppositeY;
        angle = (angle + 180) % 360;

        newHead = drawSnake(x, y, bit, angle, leadColor);

        segments.push(newHead);
        gameScene.addChild(newHead);
      }

      function turnSnake(head) {
        x = head.x;
        y = head.y;
        length = head.length;
        angle = head.angle;

        x += length * Math.round(Math.cos(angle * (Math.PI / 180)));
        y += length * Math.round(Math.sin(angle * (Math.PI / 180)));
        angle = (angle + 90) % 360;

        newHead = drawSnake(x, y, bit, angle, leadColor);
        segments.push(newHead);
        gameScene.addChild(newHead);
      }

      function moveSnake(body) {
        angle = body.angle;
        body.x += speed * Math.round(Math.cos(angle * (Math.PI / 180)));
        body.y += speed * Math.round(Math.sin(angle * (Math.PI / 180)));
      }

      function refreshHead(head) {
        x = head.x;
        y = head.y;
        length = head.length;
        angle = head.angle;

        length += speed;

        newHead = drawSnake(x, y, length, angle, leadColor);

        segments.pop();
        segments.push(newHead);
        gameScene.addChild(newHead);
        gameScene.removeChild(head);
        head.visible = false;
      }

      function refreshTail(tail) {
        x = tail.x;
        y = tail.y;
        length = tail.length;
        angle = tail.angle;
        totalLength = totalSnakeLength();

        // CHECK IF SNAKE IS MEANT TO EXTEND
        if (extendSnake && totalLength < maxSnake) {
          return;
        }
        extendSnake = false;

        // IF TAIL IS GREATER THAN BIT, REDUCE THE LENGTH AND SHIFT THE X AND Y
        if (length > bit) {
          deltaTail = speed + (totalLength - maxSnake);

          deltaTailCorrected =
            length - deltaTail < bit ? length - bit : deltaTail;

          length -= deltaTailCorrected;

          x +=
            deltaTailCorrected * Math.round(Math.cos(angle * (Math.PI / 180)));
          y +=
            deltaTailCorrected * Math.round(Math.sin(angle * (Math.PI / 180)));

          newTail = drawSnake(x, y, length, angle, tail.color);
          segments.splice(0, 1, newTail);

          tailIndex = gameScene.getChildIndex(tail);
          gameScene.addChildAt(newTail, tailIndex);
          gameScene.removeChild(tail);
          tail.visible = false;
        } else {
          // OTHERWISE THEN REMOVE THE TAIL FROM SEGMENTS AND FROM SCENE AND END FUNCTION
          if (segments.length > 1) {
            segments.shift();
            gameScene.removeChild(tail);
            tail.visible = false;

            if (segments.length > 1) {
              tail = segments[0];
              refreshTail(tail);
            }
          } else {
            maxSnake = 0;
            state = end;
          }
        }
      }

      function drawSnake(x, y, length, angle, color) {
        snake = new Graphics();
        snake.color = color;
        snake.beginFill(color);

        snake.drawRoundedRect(0, 0, length, bit, bit / 1.8);
        snake.x = x;
        snake.y = y;
        snake.length = length;
        snake.angle = angle;
        return snake;
      }

      // ESTABLISH BORDERS
      function setBorders() {
        if (borders.length == 0) {
          up = drawBorder(0);
          right = drawBorder(90);
          down = drawBorder(180);
          left = drawBorder(270);

          borders.push(up);
          borders.push(right);
          borders.push(down);
          borders.push(left);

          lastBorderColor["0"] = up.color;
          lastBorderColor["90"] = right.color;
          lastBorderColor["180"] = down.color;
          lastBorderColor["270"] = left.color;
        }

        if (borders.indexOf(up) < 0) {
          up = drawBorder(0);
          right = drawBorder(90);
          down = drawBorder(180);
          left = drawBorder(270);

          borders.push(up);
          borders.push(right);
          borders.push(down);
          borders.push(left);
        }

        if (borders.indexOf(right) < 0) {
          up = drawBorder(0);
          right = drawBorder(90);
          down = drawBorder(180);
          left = drawBorder(270);

          borders.push(up);
          borders.push(right);
          borders.push(down);
          borders.push(left);
        }

        if (borders.indexOf(down) < 0) {
          up = drawBorder(0);
          right = drawBorder(90);
          down = drawBorder(180);
          left = drawBorder(270);

          borders.push(up);
          borders.push(right);
          borders.push(down);
          borders.push(left);
        }

        if (borders.indexOf(left) < 0) {
          up = drawBorder(0);
          right = drawBorder(90);
          down = drawBorder(180);
          left = drawBorder(270);

          borders.push(up);
          borders.push(right);
          borders.push(down);
          borders.push(left);
        }
      }

      function drawBorder(angle) {
        border = new Graphics();

        border.color = borderColorArray[0];
        borderColorArray.push(borderColorArray.shift());
        border.beginFill(border.color);

        border.length = dimension;
        border.drawRect(0, 0, border.length, bit);

        length = border.length;

        if (
          Math.round(Math.cos(angle * (Math.PI / 180))) -
            Math.round(Math.sin(angle * (Math.PI / 180))) <
          0
        ) {
          xMultiplier = 1;
        } else {
          xMultiplier = 0;
        }

        if (
          Math.round(Math.sin(angle * (Math.PI / 180))) +
            Math.round(Math.cos(angle * (Math.PI / 180))) <
          0
        ) {
          yMultiplier = 1;
        } else {
          yMultiplier = 0;
        }

        border.x = length * xMultiplier;
        border.y = length * yMultiplier;
        border.angle = angle;
        gameScene.addChild(border);
        return border;
      }

      // CHECK FOR MAX OBJECTS AND PLACE OBJECTS
      function checkObjects() {
        var newTime = Date.now();
        var deltaTime = newTime - oldTime;
        if (deltaTime > 500) {
          deltaTime = 0;
          oldTime = newTime;
          if (objects.length < maxObjects) {
            drawObject();
          }
        }
      }

      function drawObject() {
        object = new Graphics();
        color = objectColorArray[0];
        object.color = color;
        object.beginFill(color);
        objectColorArray.push(objectColorArray.shift());
        xy = avoidHeadXY();
        x = xy[0]; //avoidHeadX();
        y = xy[1]; //avoidHeadY();
        centerPoint = xy[2];
        object.drawCircle(0, 0, bit / 2);
        object.x = x;
        object.y = y;
        object.centerPoint = centerPoint;

        objects.push(object);
        gameScene.addChild(object);
        objectState = 1;
        return object;
      }

      function avoidHeadXY() {
        if (objectGrid.length == 0) {
          maxSnake = 0;
          state = end;
        }

        head = segments[segments.length - 1];
        head = getCoordinates(head);
        x = null;
        counter = 0;

        while (
          counter < objectGrid.length &&
          (x == null ||
            (x > head.x0 - 0.5 * bit &&
              x < head.x0 + 1.5 * bit &&
              head.angle % 180 > 0) ||
            (y > head.y0 - 0.5 * bit &&
              y < head.y0 + 1.5 * bit &&
              head.angle % 180 == 0))
        ) {
          point = randomInt(0, objectGrid.length - 1);
          centerPoint = objectGrid[point];

          x = centerPoint[0];
          y = centerPoint[1];
          counter += 1;
        }

        // range = bit / 3;
        range = bit / 5;
        fuzzyX = Math.random() * (2 * range) - range;
        fuzzyY = Math.random() * (2 * range) - range;

        x += fuzzyX;
        y += fuzzyY;
        i = objectGrid.indexOf(centerPoint);
        objectGrid.splice(i, 1);

        return [x, y, centerPoint];
      }

      function destroyObject(object) {
        gameScene.removeChild(object);
        object.visible = false;
      }

      // ADD COLLISION DETECTION
      function objectCollision(object1, object2) {
        object1 = getCoordinates(object1);
        object2 = getCoordinates(object2);

        xOverlap = false;
        yOverlap = false;

        if (object1.x0 <= object2.x0) {
          if (object1.x1 > object2.x0) {
            xOverlap = true;
          }
        } else if (object2.x1 > object1.x0) {
          xOverlap = true;
        }

        if (object1.y0 <= object2.y0) {
          if (object1.y1 > object2.y0) {
            yOverlap = true;
          }
        } else if (object2.y1 > object1.y0) {
          yOverlap = true;
        }

        if (xOverlap && yOverlap) {
          return true;
        }
      }

      function getCoordinates(object) {
        if (object.length === undefined) {
          object.x0 = object.x - bit / 2.25;
          object.x1 = object.x + bit / 2.25;
          object.y0 = object.y - bit / 2.25;
          object.y1 = object.y + bit / 2.25;
          return object;
        } else {
          angle = object.angle;
          x = object.x;
          y = object.y;
          length = object.length;

          oppositeX =
            x +
            length * Math.round(Math.cos(angle * (Math.PI / 180))) -
            bit * Math.round(Math.sin(angle * (Math.PI / 180)));

          oppositeY =
            y +
            length * Math.round(Math.sin(angle * (Math.PI / 180))) +
            bit * Math.round(Math.cos(angle * (Math.PI / 180)));

          object.oppositeX = oppositeX;
          object.oppositeY = oppositeY;
          object.x0 = Math.min(x, oppositeX);
          object.x1 = Math.max(x, oppositeX);
          object.y0 = Math.min(y, oppositeY);
          object.y1 = Math.max(y, oppositeY);
          return object;
        }
      }

      // OLD HELPERS
      function keyboard() {
        var key = {};
        key.isDown = false;
        key.isUp = true;
        key.press = undefined;
        key.release = undefined;
        key.downHandler = (event) => {
          if (event) {
            if (key.isUp && key.press) key.press();
            key.isDown = true;
            key.isUp = false;
          }
          event.preventDefault();
        };

        key.upHandler = (event) => {
          if (event) {
            if (key.isDown && key.release) key.release();
            key.isDown = false;
            key.isUp = true;
          }
          event.preventDefault();
        };

        window.addEventListener("keydown", key.downHandler.bind(key), false);
        window.addEventListener("keyup", key.upHandler.bind(key), false);
        window.addEventListener(
          "touchstart",
          function (e) {
            // Cache the client X/Y coordinates
            clientX = e.touches[0].clientX;
            clientY = e.touches[0].clientY;
          },
          false
        );
        window.addEventListener("touchstart", key.downHandler.bind(key), false);
        window.addEventListener("touchend", key.upHandler.bind(key), false);
        window.addEventListener(
          "mousedown",
          function (e) {
            // Cache the client X/Y coordinates
            clientX = e.clientX;
            clientY = e.clientY;
          },
          false
        );
        window.addEventListener("mousedown", key.downHandler.bind(key), false);
        window.addEventListener("mouseup", key.upHandler.bind(key), false);

        //check for events in parent
        window.parent.addEventListener("keydown", key.downHandler.bind(key), {
          passive: false,
        });
        window.parent.addEventListener("keyup", key.upHandler.bind(key), false);
        window.parent.addEventListener(
          "touchstart",
          function (e) {
            // Cache the client X/Y coordinates
            clientX = e.touches[0].clientX;
            clientY = e.touches[0].clientY;
          },
          { passive: false }
        );
        window.parent.addEventListener(
          "touchstart",
          key.downHandler.bind(key),
          { passive: false }
        );
        window.parent.addEventListener("touchend", key.upHandler.bind(key), {
          passive: false,
        });
        window.parent.addEventListener(
          "mousedown",
          function (e) {
            // Cache the client X/Y coordinates
            clientX = e.clientX;
            clientY = e.clientY;
          },
          { passive: false }
        );
        window.parent.addEventListener("mousedown", key.downHandler.bind(key), {
          passive: false,
        });
        window.parent.addEventListener("mouseup", key.upHandler.bind(key), {
          passive: false,
        });

        return key;
      }

      // GAMEPLAY FUNCTIONALITY
      function increaseDifficulty(bounce) {
        if (score < 3) {
          if (maxObjects < 5) {
            maxObjects += 2;
          }
          speed += 0.05;
        } else if (score >= 3 && score < 8) {
          level = 2;
          if (maxObjects < 5) {
            maxObjects += 1;
          }
          speed += 0.05;
        } else if (score >= 8 && score < 16) {
          level = 3;
          if (maxObjects < 8) {
            maxObjects += 1;
          }
          if (speed < 3.55) {
            speed += 0.1;
          }
        } else if (score >= 16 && score < 25) {
          level = 4;
          if (maxObjects < 11) {
            maxObjects += 1;
          }
          if (speed < 4) {
            speed += 0.1;
          }
        } else if (score >= 25 && score < 40) {
          level = 5;
          if (maxObjects < 14) {
            maxObjects += 1;
          }
          if (speed < 5) {
            speed += 0.1;
          }
        } else if (score % 5 == 0 && score >= 40) {
          level += 1;
          maxObjects += score % 5;
          // speed += 0.05;
        }
      }

      function randomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      // Lock screen orientation
      var screenOrientation = window.screen.orientation;
      screenOrientation.lock("portrait");
    </script>
  </body>
</html>
`;

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center">
      <iframe srcDoc={html} width="500" height="500" title="Snake Game" />
    </div>
  );
}

export default Game;
